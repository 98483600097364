const monthNumberToString = (month: number): string => {
    switch (month) {
        case 1:
            return 'Jan';
        case 2:
            return 'Feb';
        case 3:
            return 'Mar';
        case 4:
            return 'Apr';
        case 5:
            return 'May';
        case 6:
            return 'Jun';
        case 7:
            return 'Jul';
        case 8:
            return 'Aug';
        case 9:
            return 'Sep';
        case 10:
            return 'Oct';
        case 11:
            return 'Nov';
        case 12:
            return 'Dec';
    }
}

const padToTwo = (number: number): string => {
    return number < 10 ? `0${number}` : `${number}`;
}

export const format = (mysqlDate: string): string => {
    const date: Date = new Date(mysqlDate);
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();

    return `${padToTwo(day)}-${monthNumberToString(month)}-${year}`;
}

export const generateTimeAgo = (postDate: string): string => {
    const today = new Date();
    const datePosted = new Date(postDate);
    const diff = today.getTime() - datePosted.getTime();
    const day = datePosted.getDate();
    const month = datePosted.toLocaleString('en-GB', { month: 'short' });
    const year = datePosted.getFullYear();

    if (diff < 3600000) {
        const minutes = Math.floor(diff / 60000);
        return minutes + "mins";
    }
    else if (diff < 86400000) {
        const hours = Math.floor(diff / 3600000);
        return hours + "h";
    }
    else if (diff < 604800000) {
        const days = Math.floor(diff / 86400000);
        return days + "d";
    }
    else if (diff < 2592000000) {
        const weeks = Math.floor(diff / 604800000);
        return weeks + "w";
    }
    else if (diff < 31536000000) {
        return day + " " + month;
    }
    else {
        return day + " " + month + " " + year;
    }
}