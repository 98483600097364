import axios, { AxiosInstance } from "axios";

export const API_DEFAULT_TIMEOUT = 30 * 1000;

export const client: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URI,
    timeout: API_DEFAULT_TIMEOUT,
});

/**
 * Each hook for SWR mutation already has the Axios global object. We need to set the captcha
 * code with an interceptor for a captcha-authenticated call to work. We also need to explicitly
 * eject previous interceptors doing the same on each attempt, to ensure we don't try to reuse
 * the same single-use captcha token.
 */
export const prepareCaptchaInterceptor = (client: any, captchaCode: string) => {
    const interceptorCount = client.interceptors.request.handlers.length;
    if (interceptorCount > 0) {
        for (let ii = interceptorCount; ii > 0; ii--) {
            client.interceptors.request.eject(ii - 1);
        }
    }

    client.interceptors.request.use(
        (config) => {
            config.headers['x-recaptcha-code'] = captchaCode;
            return config;
        },
        error => {
            Promise.reject('Captcha intercept error: ' + error);
        }
    );
}
