// Copied from example at https://github.com/teobler/swr-request-generator/blob/v1.2.2/example/src/request/useGetRequest.ts

import useSWR, {SWRResponse, unstable_serialize} from "swr";
import { SWRConfiguration } from "swr/_internal";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { client } from "./client";

export interface Return<Data, Error>
    extends Pick<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, "isValidating" | "error" | "mutate" | "isLoading"> {
    data: Data | undefined;
    response: AxiosResponse<Data> | undefined;
}

export interface SWRConfig<Data = unknown, Error = unknown>
    extends Omit<SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>, "onSuccess"> {
    onSuccess?: (response: AxiosResponse<Data>, key: string) => void;
    shouldFetch?: boolean;
}

export const generateSwrConfigWithShouldFetchProperty = <Data, Error>(
    SWRConfig?: SWRConfig<Data, Error>,
): SWRConfig<Data, Error> =>
    SWRConfig
        ? !SWRConfig.shouldFetch
            ? SWRConfig
            : {
                ...SWRConfig,
                shouldFetch: true,
            }
        : { shouldFetch: true };

export const useGetRequest = <Data = unknown, Error = unknown>(
    axiosConfig: AxiosRequestConfig,
    SWRConfig?: SWRConfig<Data, Error>,
): Return<Data, Error> => {
    const swrConfig = generateSwrConfigWithShouldFetchProperty(SWRConfig);
    const shouldFetch = swrConfig.shouldFetch;
    delete swrConfig.shouldFetch;

    const {
        data: response,
        error,
        isValidating,
        mutate,
        isLoading,
    } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => (shouldFetch ? unstable_serialize([axiosConfig.url!, axiosConfig.params]) : null),
        () => client(axiosConfig),
        swrConfig,
    );
    return { data: response && response.data, response, error, isValidating, isLoading, mutate };
};
