/** Role id for ROLE_ADMIN **/
export const ROLE_ADMIN_ID = 1;

/** Role id for ROLE_SUB_ADMIN **/
export const ROLE_SUB_ADMIN_ID = 2;

/** Role id for ROLE_SCHOOL_ADMIN **/
export const ROLE_SCHOOL_ADMIN_ID = 3;

/** Role id for ROLE_EMPLOYER **/
export const ROLE_EMPLOYER_ID = 4;

/** Role id for ROLE_UNIVERSITY **/
export const ROLE_UNIVERSITY_ID = 5;

/** Role id for ROLE_SCHOOL_USER **/
export const ROLE_SCHOOL_USER_ID = 6;

/** Role id for ROLE_SAS_USER  **/
export const ROLE_SAS_USER_ID = 7;

/** Role id for ROLE_SAS_USER  **/
export const ROLE_SCHOOL_SUB_ADMIN_ID = 8;

/** Role id for ROLE_GROUP_ADMIn_USER  **/
export const ROLE_GROUP_ADMIN_ID = 9;

/** Role id for ROLE_PROFILE_ADMIn_USER  **/
export const ROLE_PROFILE_ADMIN_ID = 10;

/** Role id for ROLE_APPRENTICE_ID  **/
export const ROLE_APPRENTICE_ID = 11;
