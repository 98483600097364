import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "tss-react/mui";

import Button from "../CustomButtons/Button";
import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import Grid from "@mui/material/Grid";
import { useRouter } from "next/router";

const useStyles = makeStyles()(styles);

export default function RegisterSwitch({
  closeFromInside,
  showFooter = false,
  addQuery = {},
}) {
  const { classes } = useStyles();
  const router = useRouter();

  const handleClick = async (type, e) => {
    e.stopPropagation();

    router.query = {...router.query, ...addQuery, register: type};

    await router.push(router, undefined, {shallow: true});
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <label className={classes.label}>What type of user are you?</label>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="lightBlue"
            className={classes.blockButton}
            onClick={handleClick.bind(null, "student")}
          >
            Student
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="lightBlue"
            className={classes.blockButton}
            onClick={handleClick.bind(null, "employer")}
          >
            Employer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <a
            href={`https://successatschool.us3.list-manage.com/subscribe?u=2c038531b25876a2c7c9cf8b1&id=075a5d0dae${
              addQuery?.email ? `&MERGE0=${encodeURI(addQuery.email)}` : ""
            }`}
          >
            <Button color="lightBlue" className={classes.blockButton}>
              Parents
            </Button>
          </a>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="lightBlue"
            className={classes.blockButton}
            onClick={handleClick.bind(null, "teacher")}
          >
            Teacher / Careers Leader
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="lightBlue"
            className={classes.blockButton}
            onClick={handleClick.bind(null, "university")}
          >
            University
          </Button>
        </Grid>
        {showFooter && (
          <>
            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <label className={classes.label}>Why do I need to sign up?</label>
              <p>
                Register with Success at School to gain access to our free
                newsletter and careers portal.
              </p>

              <p>
                Get the latest apprenticeships & events delivered to your inbox,
                then save jobs & advice within your online profile.
              </p>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

RegisterSwitch.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
