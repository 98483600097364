// @material-ui/core components
import { makeStyles } from "tss-react/mui";
import { Modal, Drawer } from "@mui/material";

// core components
import Button from "../CustomButtons/Button";
import Image from "next/image.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/forumsStyle.js";

// local assets
import closeIcon from "../../public/img/forums/icons/closeIcon.png";

const useStyles = makeStyles()(styles);

export default function ForumsModal(props) {
    const { classes, cx } = useStyles();
    const { className, children, closeButton, width, ...rest } = props;
    return (
        <>
            <Modal
                className={cx(classes.modalRoot + " " + classes.desktopView)}
                open={props.open}
                onClose={props.onClose}
            >
                <div className={cx(classes.modal+ " " + width)}>
                    {closeButton && <div className={classes.modalCloseButtonHeader}>
                        <Button className={classes.modalCloseButton} onClick={props.onClose}>
                            <Image className={classes.modalIcon} src={closeIcon} alt={"close-icon"} width={20} height={20} />
                        </Button>
                    </div>}
                    {children}
                </div>
            </Modal>
            <Drawer
                classes={{ paper: classes.drawer }}
                className={cx(classes.modalRoot + " " + classes.mobileView + " " + classes.drawer)}
                open={props.open}
                onClose={props.onClose}
                anchor={"bottom"}
                sx={{ borderRadius: "100px !important" }}
            >
                <div className={classes.modalCloseButtonHeader}>
                    {closeButton && <Button className={classes.modalCloseButton} onClick={props.onClose}>
                        <Image className={classes.modalIcon} src={closeIcon} alt={"close-icon"} width={20} height={20} />
                    </Button>}
                </div>
                <div className={classes.drawerContent}>
                    {children}
                </div>
            </Drawer>
        </>
    )
}

