/**
 * @param title An unknown title string that may contain special characters.
 * @returns A URL-ready slug.
 */
export const buildSlug = (title: string): string => {
  return title.toLowerCase().replace(/(_|\W)/g, '-') // Any non-word, non-number characters, and _, to become "-".
    .replace(/-+/g, '-') // Any extra dashes to become a single dash.
    .replace(/^-+|-+$/g, '') // Any leading or trailing dashes to be removed.
    .replace(/([^\w\s\d\-_~,;\[\]\(\).])/g, '') // Any remaining special characters to be removed.
    .replace(/([.]{2,})/g, '') // Any remaining double dots to be removed.
  ;
}

export const getHostname = (url: string): string => {
    if (!url) {
        return '';
    }

    const { hostname } = new URL(url);
    return hostname;
}

export const updateQueryString = (uri: string, key: string, value?: string) => {
    const re = new RegExp("([?&])" + key + "(=.*)?(&|$)", "i");
    const separator = uri.indexOf('?') !== -1 ? "&" : "?";
    const optionalValue = value != null ? "=" + value : '';

    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + optionalValue + '$2');
    }
    else {
        return uri + separator + key + optionalValue;
    }
}