// @material-ui/core components
import { makeStyles } from "tss-react/mui";

// core components
import Button from "../CustomButtons/Button";
import { Divider } from "@mui/material";
import Image from "next/image.js";
import Link from "next/link.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/forumsStyle.js";

// local assets
import questionUpvotedIcon from "../../public/img/forums/icons/questionUpvotedIcon.png";
import commentUpvotedIcon from "../../public/img/forums/icons/commentUpvotedIcon.png";
import questionApprovedIcon from "../../public/img/forums/icons/questionApprovedIcon.png";
import questionReplyIcon from "../../public/img/forums/icons/questionReplyIcon.png";
import commentReplyIcon from "../../public/img/forums/icons/commentReplyIcon.png";
import taggedInCommentIcon from "../../public/img/forums/icons/taggedInCommentIcon.png";
import newCommunityQuestionIcon from "../../public/img/forums/icons/newCommunityQuestionIcon.png";
import commentRemovedIcon from "../../public/img/forums/icons/commentRemovedIcon.png";
import noNotificationsIcon from "../../public/img/forums/icons/followIcon.png";
import {generateTimeAgo} from "../../util/date";

const useStyles = makeStyles()(styles);

export default function ForumsNotifications(props) {
    const { classes, cx } = useStyles();
    const modalView = props.modalView;
    const onClose = props.onClose;

    const referenceArray = [
        {
            type: "questionUpvoted",
            icon: questionUpvotedIcon,
            title: "Question Upvoted",
            description: "Your question is gaining attention and has received more upvotes",
        },
        {
            type: "commentUpvoted",
            icon: commentUpvotedIcon,
            title: "Comment Upvoted",
            description: "Your input is appreciated and has been upvoted!",
        },
        {
            type: "questionApproved",
            icon: questionApprovedIcon,
            title: "Your question has been approved by a moderator",
            description: "Your question is now live on the community.",
        },
        {
            type: "questionReply",
            icon: questionReplyIcon,
            title: "New Reply to Your Question",
            description: "Someone has replied to your question",
        },
        {
            type: "commentReply",
            icon: commentReplyIcon,
            title: "New Reply to Your Comment",
            description: "Someone has replied to your comment",
        },
        {
            type: "taggedInComment",
            icon: taggedInCommentIcon,
            title: "Someone tagged you in a comment",
            description: "Someone tagged you in a comment",
        },
        {
            type: "newCommunityQuestion",
            icon: newCommunityQuestionIcon,
            title: "A new question has been posted in a community you're following",
            description: "Check it out and share your knowledge",
        },
        {
            type: "questionRemoved",
            icon: commentRemovedIcon,
            title: "Your comment has been removed as it did not meet the community guidelines",
            description: "Please review the rules and contribute positively",
        },
    ]

    // TESTING hardcoded notifications

    const notifications = [
        {
            type: "questionUpvoted",
            date: "2024-09-03T12:00:00Z",
        },
        {
            type: "commentUpvoted",
            date: "2024-09-03T12:00:00Z",
        },
        {
            type: "questionApproved",
            date: "2024-08-26T12:00:00Z",
        },
        {
            type: "questionReply",
            date: "2024-08-26T12:00:00Z",
        },
        {
            type: "commentReply",
            date: "2024-08-26T12:00:00Z",
        },
        {
            type: "taggedInComment",
            date: "2024-08-26T12:00:00Z",
        },
        {
            type: "newCommunityQuestion",
            date: "2024-08-26T12:00:00Z",
        },
        {
            type: "questionRemoved",
            date: "2024-08-26T12:00:00Z",
        },
    ];

    const generateNotification = (notification, index) => {
        const refIndex = referenceArray.findIndex(ref => ref.type === notification.type);
        return (
            <>
                <div className={classes.notification} key={index}>
                    <div className={classes.notificationContent}>
                        <div className={classes.notificationIconContainer}>
                            <Image
                                src={referenceArray[refIndex].icon}
                                alt="notification-icon"
                                fill
                                className={classes.notificationIcon}
                            />
                        </div>
                        <div className={classes.notificationTextContainer}>
                            <p className={classes.notificationTitle}>{referenceArray[refIndex].title}</p>
                            <p className={classes.notificationDescription}>{referenceArray[refIndex].description}</p>
                        </div>
                    </div>
                    <p className={classes.notificationTimeAgo}>{generateTimeAgo(notification.date)}</p>
                </div>
                {modalView && <Divider />}
            </>

        )
    }

    return (
        <>
            <div className={cx(classes.modalContent + " " + classes.notifications + (modalView ? " " + classes.notificationModal : ""))}>
                {notifications.length === 0 &&
                    <div className={classes.noTopicsBox}>
                        <>
                            <Image className={classes.noTopicsIcon} src={noNotificationsIcon} alt={"no-notifications-icon"} width={40} height={40} />
                            <h2 className={classes.noTopicsTitle}>No notifications yet!</h2>
                            <p className={classes.noTopicsText}>There are no new notifications at the moment. Check back later or continue exploring the community to stay updated.
                            </p>
                        </>
                    </div>
                }
                {notifications.length > 0 &&
                    <>
                        <Button className={classes.markAsReadText} onClick={onClose}>
                            Mark all as read
                        </Button>
                        <div className={classes.notificationCategoryHeader}>
                            <p className={classes.notificationCategory}>TODAY</p>
                        </div>
                        <div className={classes.todayNotificationContainer + (modalView ? " " + classes.todayNotificationsIfModal : "")}>
                            {notifications.map((notification, index) => {
                                if (new Date() - new Date(notification.date) < 86400000) {
                                    return (
                                        generateNotification(notification, index)
                                    )
                                }
                            })}
                        </div>
                        <div className={classes.notificationCategoryHeader}>
                            <p className={classes.notificationCategory}>EARLIER</p>
                        </div>
                        <div className={classes.notificationContainer  + (modalView ? " " + classes.notificationsIfModal : "")}>
                            {notifications.map((notification, index) => {
                                if (new Date() - new Date(notification.date) >= 86400000) {
                                    return (
                                        generateNotification(notification, index)
                                    )
                                }
                            })}
                        </div>
                        {modalView && <Link className={classes.notificationsLink} href={"/community/notifications"} >See all</Link>}
                    </>}
            </div>
        </>
    )
}

