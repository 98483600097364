import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "tss-react/mui";

import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";

import { client, prepareCaptchaInterceptor } from "../../request/client";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  UserForRegistration,
  useUserResetPasswordRequest,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
const useStyles = makeStyles()(styles);

export default function ResetPasswordForm({ hash, closeFromInside }) {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  /**
   * @type {UserForRegistration}
   */
  const [password, setPassword] = useState({
    first: "",
    second: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const { classes } = useStyles();
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const { isMutating, trigger: registerEmployer } = useUserResetPasswordRequest(
    { key: hash }
  );

  useEffect(() => {
    window.gtag && window.gtag("event", "reset_password_form");
  }, []);

  const handleInputChange = (event) => {
    setPassword({
      ...password,
      [event.target.name.replace("register_", "")]: event.target.value,
    });
  };

  /**
   * Order of steps for successful registration:
   * 1. Submit pressed: `prepareCaptchaAndSubmit()`
   * 2. Captcha solved: `handleCaptchaChange()`
   * 3. Captcha new value callback sets token and calls `register()`
   */
  const prepareCaptchaAndSubmit = (event) => {
    event.preventDefault();
    recaptchaRef.current.reset(); // Make it work even if a past solution failed or timed out.
    recaptchaRef.current.execute(); // Get a token invisibly, or via a challenge if necessary.
  };

  const resetCaptcha = () => {
    // registrationComplete being true should cause the callback to auto-login
    // instead of trying to register again.
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  /**
   * @param {?string} captchaCode
   */
  const handleCaptchaChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }

    // We pass this to `register()` directly because the timing didn't work when
    // using a setter and `useState(...)` while also naively calling a submission
    // method right after.
    submit(captchaCode);
  };

  /**
   * @param {?string} captchaCode
   */
  const submit = async (captchaCode) => {
    setErrors({});
    setErrorMessage("");

    prepareCaptchaInterceptor(client, captchaCode);
    try {
      const response = await registerEmployer({
        body: { password },
      });

      window.gtag && window.gtag("event", "reset_password_submit");

      if (response.data.success) {
        setSuccess(true);
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage(
        e.response?.data?.errors?.length > 0
          ? e.response?.data?.errors[0]
          : e.response?.data?.message || e.message
      );
      setErrors(e.response.data.errors);
    }
  };

  if (success) {
    return (
      <>
        <SnackbarContent
          message="Password has been reset successfully. Please login with your new password."
          color="success"
          icon={Check}
        />
      </>
    );
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={prepareCaptchaAndSubmit}>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleCaptchaChange}
          ref={recaptchaRef}
          size="invisible"
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <TextField
                error={!!errors?.first}
                helperText={errors?.first}
                required
                id="first"
                name="first"
                type="password"
                value={password.first}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="second" className={classes.label}>
                Confirm Password
              </label>
              <TextField
                error={!!errors?.second}
                helperText={errors?.second}
                required
                id="second"
                name="second"
                type="password"
                value={password.second}
                onChange={handleInputChange}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {errorMessage ? (
              <SnackbarContent
                message={errorMessage}
                color="warning"
                icon={Warning}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type={"submit"}
              disabled={isMutating}
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

ResetPasswordForm.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
