import React, { useCallback, useEffect, useMemo, useState } from "react";
import Link from "next/link";
import Image from "next/image";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";

// @mui/icons-material
import Menu from "@mui/icons-material/Menu";
import Button from "../CustomButtons/Button";

// core components
import styles from "../../styles/jss/nextjs-material-kit/components/headerStyle.js";

import UserOptionsModal from "../UserOptionsModal/UserOptionsModal.js";
import ForumsModal from "../ForumsModal/ForumsModal.js";
import ForumsNotifications from "../ForumsNotifications/ForumsNotifications.js";

import { useAuthContext } from "../../contexts/auth";
import { useRouter } from "next/router";
import { updateQueryString } from "../../util/url";
import {
  ROLE_ADMIN_ID,
  ROLE_APPRENTICE_ID,
  ROLE_EMPLOYER_ID,
  ROLE_SCHOOL_ADMIN_ID,
  ROLE_SCHOOL_USER_ID,
  ROLE_SUB_ADMIN_ID,
  ROLE_UNIVERSITY_ID,
} from "../../util/roles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { RegisterDialog } from "../RegisterDialog/RegisterDialog.js";
import { VerifyPasswordDialog } from "../VerifyPasswordDialog/VerifyPasswordDialog.js";
import { ForgottenPasswordDialog } from "../ForgottenPasswordDialog/ForgottenPasswordDialog.js";
import { LoginDialog } from "../LoginDialog/LoginDialog.js";
import { NagDialog } from "../NagDialog/NagDialog.js";
import { Box } from "@mui/material";

import notificationsIcon from "../../public/img/forums/icons/notificationBell.png";
import noNotificationsIcon from "../../public/img/forums/icons/notificationBellBlank.png";
import userPlaceholder from "../../public/img/no_profile_white.png";

const useStyles = makeStyles()(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

export const getDashboardUrl = (user) => {
  if (user.role === ROLE_ADMIN_ID) {
    return "/superadmin/dashboard";
  } else if (user.role === ROLE_SUB_ADMIN_ID) {
    return "/superadmin/dashboard";
  } else if (user.role === ROLE_SCHOOL_ADMIN_ID) {
    return "/admin/school";
  } else if (user.role === ROLE_SCHOOL_USER_ID) {
    return "/student/dashboard";
  } else if (user.role === ROLE_EMPLOYER_ID) {
    return "/employer/dashboard";
  } else if (user.role === ROLE_UNIVERSITY_ID) {
    return "/university/dashboard";
  } else if (user.role === ROLE_APPRENTICE_ID) {
    return "/apprentice/dashboard";
  } else {
    return "/";
  }
};

export default function Header(props) {
  const { classes, cx } = useStyles();
  const { auth, logout } = useAuthContext();
  const router = useRouter();

  const [modal, setModal] = useState(props.initialModal);

  const [mobileOpen, setMobileOpen] = useState(false);

  const [userOptionsOpen, setUserOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  // Needs API integration - hardcoded notifs to get length for count
  const notifications = [];

  const notificationIcon = () => {
    if (notifications.length > 0) {
      return (
        <>
          <Image className={classes.notificationIcon} src={notificationsIcon} alt={"notification-bell-icon"} width={25} height={21} />
          <div className={classes.notificationCountContainer}>
            <p className={classes.notificationCount}>{notifications.length}</p>
          </div>
        </>
      )
    } else {
      return(
        <>
          <Image className={classes.notificationIcon} src={noNotificationsIcon} alt={"notification-bell-icon"} width={25} height={21} />
        </>
      )
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!auth.user && !modal) {
        setModal((modal) => modal || "nag");
      }
    }, 7 * 1000);

    return () => clearTimeout(timer);
  }, [auth]);

  useEffect(() => {
    if (router.query.login !== undefined) {
      setModal("login");
    }
  }, [router.query.login]);

  useEffect(() => {
    if (router.query.register !== undefined) {
      setModal("register");
    }
  }, [router.query.register]);

  useEffect(() => {
    if (router.query.password !== undefined) {
      setModal("forgotten");
    }
  }, [router.query.password]);

  useEffect(() => {
    if (router.query.verify !== undefined) {
      setModal("verify");
    }
  }, [router.query.verify]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((open) => !open);
  }, []);

  const { color, rightLinks, logo, fixed, absolute } = props;
  const appBarClasses = cx({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  const brandComponent = (
    <Link href="/" as="/">
      <div className={classes.logo}>
        <Image
          height={47}
          width={128}
          src={logo.src}
          loading="eager"
          priority
          alt="Success at School"
        />
      </div>
    </Link>
  );

  let handleLogout = useCallback((e) => {
    e.preventDefault();

    logout();
  }, []);

  const handleModalClose = useCallback(() => {
    setModal(false);
  }, []);

  const handleLoginSuccess = useCallback(async (user) => {
    if (router.query.redirectUrl) {
      await router.push(router.query.redirectUrl);
    } else {
      await router.push(getDashboardUrl(user));
    }
  }, [router]);

  const dialogClasses = useMemo(
    () => ({
      root: classes.center,
      paper: classes.modal,
    }),
    []
  );

  const handleUserOptionsOpen = (event) => {
    setUserOptionsOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handleUserOptionsClose = () => {
    setUserOptionsOpen(false);
    setAnchorEl(null);
  }

  const handleNotificationsOpen = () => {
    setNotificationsOpen(true);
  }

  const handleNotificationsClose = () => {
    setNotificationsOpen(false);
  }

  return (
    <AppBar className={cx(appBarClasses, "newheader")}>
      <div className={"topBar"}>
        <div className={classes.container}>
          <div>
            <p style={{ padding: 0, fontSize: "14px" }}>
              <Link
                href="/page/employers"
                style={{ textDecoration: "underline" }}
              >
                Employers
              </Link>{" "}
              and{" "}
              <Link
                href="/page/university"
                style={{ textDecoration: "underline" }}
              >
                Universities:
              </Link>{" "}
              Work with us?
            </p>
          </div>
          <div className={cx(classes.socialIcons, "socialIcons")}>
            <a
              href="https://www.youtube.com/channel/UChRZlNI4577XtlLibJNeANQ"
              className={classes.socials}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              href="https://www.instagram.com/successatschool"
              className={classes.socials}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.linkedin.com/company/success-at-school"
              className={classes.socials}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a
              href="https://www.facebook.com/successatschool.org"
              className={classes.socials}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="http://twitter.com/successatschool"
              className={classes.socials}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <p style={{ float: "right", fontSize: "14px" }}>
              {auth.user ? (
                <>
                  {/*<Button onClick={handleNotificationsOpen} className={classes.iconContainer}>*/}
                  {/*  {notificationIcon()}*/}
                  {/*</Button>*/}
                  <ForumsModal open={notificationsOpen} onClose={handleNotificationsClose} closeButton={false} width={classes.width50}>
                    <ForumsNotifications onClose={handleNotificationsClose} modalView={true}/>
                  </ForumsModal>
                  <Button onClick={handleUserOptionsOpen} className={classes.iconContainer}>
                    <Image className={classes.avatarImage} src={
                      auth.user?.image || userPlaceholder
                    } alt={"user-profile-image"} width={27} height={27} />
                  </Button>
                  <UserOptionsModal
                    open={userOptionsOpen}
                    onClose={handleUserOptionsClose}
                    anchorEl={anchorEl}
                    handleLogout={handleLogout}
                    getDashboardUrl={getDashboardUrl}
                    auth={auth}
                    logout={logout}
                  />
                </>
              ) : (
                <>
                  <Link
                    href={updateQueryString(router.asPath, "login", null)}
                    shallow={true}
                  >
                    Login
                  </Link>
                  &nbsp; or &nbsp;
                  <Link
                    href={updateQueryString(router.asPath, "register", null)}
                    shallow={true}
                  >
                    Signup
                  </Link>

                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <Toolbar className={classes.logoHeader}>
        <div className={classes.flex}>{brandComponent}</div>
        <Box
          sx={{ display: { xs: "none", md: "block" } }}
          className={classes.menuWrapper}
        >
          {rightLinks}
        </Box>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open mobile menu"
            onClick={handleDrawerToggle}
            size="large"
          >
            <Menu />
          </IconButton>
        </Box>
      </Toolbar>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>{rightLinks}</div>
        </Drawer>
      </Box>

      <ForgottenPasswordDialog
        classes={dialogClasses}
        open={modal === "forgotten"}
        TransitionComponent={Transition}
        onClose={handleModalClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      />

      <VerifyPasswordDialog
        classes={dialogClasses}
        open={modal === "verify"}
        TransitionComponent={Transition}
        onClose={handleModalClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      />

      <LoginDialog
        classes={dialogClasses}
        open={modal === "login"}
        TransitionComponent={Transition}
        onClose={handleModalClose}
        onSuccess={handleLoginSuccess}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      />

      <RegisterDialog
        classes={dialogClasses}
        open={modal === "register"}
        TransitionComponent={Transition}
        onClose={handleModalClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      />

      <NagDialog
        classes={dialogClasses}
        open={modal === "nag"}
        TransitionComponent={Transition}
        onClose={handleModalClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      />
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  logo: PropTypes.object,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};
