import React, { memo, useCallback, useState, useEffect } from "react";
import Slide from "@mui/material/Slide";
import LoginForm from "../LoginForm/LoginForm";
import { useRouter } from "next/router";
import { makeStyles } from "tss-react/mui";
import styles from "../../styles/jss/nextjs-material-kit/pages/forumsStyle.js";

import ForumsModal from "../ForumsModal/ForumsModal";

const useStyles = makeStyles()(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

export const LoginDialog = memo(function LoginDialog({ ...props }) {
  const { classes, cx } = useStyles();

  const router = useRouter();

  const isForums = router.pathname.includes("/community");

  const handleClose = useCallback(async () => {
    delete router.query.login;
    delete router.query.redirectUrl;
    await router.push(router);
    props.onClose();
  }, [router, props.onClose]);

  return (
    <ForumsModal open={router.query.login !== undefined} onClose={handleClose} closeButton={true} width={classes.width50}>
      <div className={classes.loginModalContainer}>
      <div
        id="classic-modal-slide-title"
        className={classes.loginModalHeader}
      >
        <span className={classes.loginModalTitle}>Login</span>
        {isForums && 
        <p className={classes.modalSubtitle}>To engage with the community, you need to be logged in</p>
        }
      </div>
      <div
        id="classic-modal-slide-description"
        className={classes.loginModalBody}
      >
        <LoginForm closeFromInside={handleClose} onSuccess={props.onSuccess} />
      </div>
    </div>
    </ForumsModal>
  );
});
