import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont,
} from "../../nextjs-material-kit.js";

const customInputStyle = (theme) => ({
  disabled: {
    "&:before": {
      borderColor: "transparent !important",
    },
  },
  underline: {
    "&:hover:not(.Mui-disabled):before,&:before": {
      borderColor: "#D2D2D2 !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor,
    },
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor,
    },
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor,
    },
  },
  whiteUnderline: {
    "&:hover:not(.Mui-disabled):before,&:before": {
      borderColor: "#FFFFFF",
    },
    "&:after": {
      borderColor: "#FFFFFF",
    },
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "2em",
    left: '4px',
    letterSpacing: "unset",
    transform: 'translate(0px, 4px) scale(1)',
    "& + .MuiInput-underline": {
      marginTop: "0px",
    },
    "&.MuiInputLabel-shrink": {
      transform: 'translate(0px, -20px) scale(0.75)',
    },
  },
  // Some tweaks required for job detail page – not clear why yet.
  labelRootVariant: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    left: '4px',
    top: '8px',
    zIndex: '10',
    letterSpacing: "unset",
    "& + .MiuInput-Underline": {
      marginTop: "0px",
    },
  },
  labelRootError: {
    color: dangerColor + " !important",
  },
  labelRootSuccess: {
    color: successColor + " !important",
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#495057",
    },
  },
  input: {
    color: "#495057",
    height: "unset",
    padding: '2px',
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: "var(--font-brandon-grotesque)",
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: "#AAAAAA",
    },
  },
  whiteInput: {
    "&,&::placeholder": {
      color: "#FFFFFF",
      opacity: "1",
    },
  },
  inputHomeSearch: {
    padding: "0",
    margin: "0",
    background: "white",

    "& input": {
      height: "24px",
    },
    "& label": {
      transform: "translate(0px, 1px) scale(1)",
    },
  },
});

export default customInputStyle;
