/*!
 * Coded by Joel Muriuki
 */
import React, {memo, useEffect} from "react";
// import ReactDOMClient from "react-dom/client";
import moment from "moment";
import Head from "next/head";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { createEmotionSsrAdvancedApproach } from "tss-react/next/pagesDir";
import Script from "next/script";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localFont from "next/font/local";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { AuthProvider } from "../contexts/auth";

import "../styles/scss/nextjs-material-kit.scss?v=1.2.0";
import "../styles/scss/core/_custom.scss?v=1.2.0";
import { LocalizationProvider } from "@mui/x-date-pickers";
import PiwikProProvider from "@piwikpro/next-piwik-pro";
import {useRouter} from "next/router";

config.autoAddCss = false;

const { augmentDocumentWithEmotionCache, withAppEmotionCache } =
  createEmotionSsrAdvancedApproach({ key: "css" });

const brandonGrotesque = localFont({
  src: [
    {
      path: "../public/fonts/BrandonGrotesque-Regular.otf",
      weight: "normal",
    },
    {
      path: "../public/fonts/BrandonGrotesque-Medium.otf",
      weight: "500",
    },
    {
      path: "../public/fonts/BrandonGrotesque-Bold.otf",
      weight: "700",
    },
    {
      path: "../public/fonts/BrandonGrotesque-Black.otf",
      weight: "900",
    },
  ],
  fallback: ["brandon grotesque", "impact", "open sans"],
  variable: "--font-brandon-grotesque",
  display: "swap",
});

const theme = createTheme({
  typography: {
    fontFamily: brandonGrotesque.style.fontFamily,
  },
  components: {
    MuiModal: {
      defaultProps: {
        classes: {
          root: `${brandonGrotesque.variable}`,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        classes: {
          root: `${brandonGrotesque.variable}`,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        classes: {
          root: `${brandonGrotesque.variable}`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: brandonGrotesque.style.fontFamily,
          "&:-webkit-autofill": {
            "-webkit-font-family": brandonGrotesque.style.fontFamily,
            "-webkit-box-shadow": "0 0 0 100px white inset",
            "-webkit-text-fill-color": "black",
          },
        },
      },
    },
  },
});

// https://www.npmjs.com/package/react-dates#localization
moment.locale("en-GB");

// TODO work out how to use the new ReactDOM root API with server rendering not breaking.
// https://github.com/reactwg/react-18/discussions/5nft monitor trace
// https://stackoverflow.com/questions/60629258/next-js-document-is-not-defined
// const transitionContainer = document.getElementById("page-transition");
// const root = ReactDOMClient.createRoot(transitionContainer);
//
//  Router.events.on("routeChangeStart", (url) => {
//   console.log(`Loading: ${url}`);
//   document.body.classList.add("body-page-transition");
//   ReactDOM.render(
//     <PageChange path={url} />,
//     document.getElementById("page-transition")
//   );
//   //root.render(<PageChange path={url} />);
// });
// Router.events.on("routeChangeComplete", () => {
//   ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
//   //root.unmount();
//   document.body.classList.remove("body-page-transition");
// });
// Router.events.on("routeChangeError", () => {
//   ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
//   document.body.classList.remove("body-page-transition");
// });

function TrackingScripts() {
  const gaId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

  return (
    <>
      <Script
        src="/assets/libs/cookie-content/cookie-consent-4.1.0.js"
        charset="UTF-8"
        onLoad={() => {
          window.ppms?.cm.api(
            "setInitialComplianceSettings",
            {
              consents: ["analytics"],
            },
            console.log,
            console.error
          );

          window.cookieconsent.run({
            notice_banner_type: "interstitial",
            consent_type: "express",
            palette: "light",
            language: "en_gb",
            page_load_consent_levels: ["strictly-necessary"],
            notice_banner_reject_button_hide: true,
            preferences_center_close_button_hide: false,
            page_refresh_confirmation_buttons: false,
            website_name: "Success At School",
            callbacks: {
              scripts_specific_loaded: (level) => {
                switch (level) {
                  case "targeting":
                    window.ppms?.cm.api(
                      "setComplianceSettings",
                      {
                        consents: {
                          analytics: {
                            status: 1,
                          },
                        },
                      },
                      console.log,
                      console.error
                    );

                    gtag("consent", "update", {
                      ad_storage: "granted",
                      ad_user_data: "granted",
                      ad_personalization: "granted",
                      analytics_storage: "granted",
                    });
                    break;
                }
              },
            },
            callbacks_force: true,
          });
        }}
      ></Script>

      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        strategy="afterInteractive"
        data-cookie-consent="tracking"
        onLoad={() => {
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          // gtag("consent", "default", {
          //   ad_storage: "denied",
          //   ad_user_data: "denied",
          //   ad_personalization: "denied",
          //   analytics_storage: "denied",
          // });
          gtag("js", new Date());
          gtag("config", gaId);
        }}
      ></Script>

      <Script
        strategy="afterInteractive"
        type="text/plain"
        data-cookie-consent="tracking"
        id="hotjar-script"
      >
        {`(function(h,o,t,j,a,r){
        h.hj = h.hj || function () {
          (h.hj.q = h.hj.q || []).push(arguments)
        };
        h._hjSettings={hjid:4938883,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
    </>
  );
}

const OptionalPiwikProProvider = memo(function OptionalPiwikProProvider({
  children,
}) {
  if (
    process.env.NEXT_PUBLIC_CONTAINER_ID &&
    process.env.NEXT_PUBLIC_CONTAINER_URL
  ) {
    return (
      <PiwikProProvider
        containerUrl={process.env.NEXT_PUBLIC_CONTAINER_URL}
        containerId={process.env.NEXT_PUBLIC_CONTAINER_ID}
      >
        {children}
      </PiwikProProvider>
    );
  }

  return children;
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const removeSlots = function () {
      console.log('window', window);
      window?.googletag?.cmd?.push(function () {  googletag.destroySlots()  })
    }

    router.events.on('routeChangeComplete', removeSlots)
    return () => {
      router.events.off('routeChangeComplete', removeSlots)
    }
  }, [])

  return (
    <React.Fragment>
      <Head>
        <title>
          Success at School: Careers advice for schools and students
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0 shrink-to-fit=no"
        />
        <meta
          key="keywords"
          name="keywords"
          content="careers advice, young people, students"
        />
        <meta
          key="desc"
          name="description"
          content="Career advice for schools and students aged 13-19. Search jobs, advice &amp; find out about employers, work experience, courses, career choices, apprenticeships and university."
        />
        <meta property="og:description" content="Success at school" />
        <meta
          property="og:image"
          content="https://successatschool.org/apple-touch-icon.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <main
        className={[brandonGrotesque.className, brandonGrotesque.variable].join(
          " "
        )}
      >
          <OptionalPiwikProProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  {/*See: https://github.com/dozoisch/react-google-recaptcha/issues/250*/}
                  {/*<React.StrictMode>*/}
                  <AuthProvider>
                    <Component {...pageProps} />
                    <TrackingScripts />
                  </AuthProvider>
                  {/*</React.StrictMode>*/}
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </OptionalPiwikProProvider>
      </main>
    </React.Fragment>
  );
}

export { augmentDocumentWithEmotionCache };

export default withAppEmotionCache(MyApp);
