import React, { memo, useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";
import { useRouter } from "next/router";
import VerifyUserForm from "../VerifyUserForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

export const VerifyPasswordDialog = memo(function VerifyPasswordDialog({
  ...props
}) {
  const router = useRouter();

  useEffect(() => {
    window.gtag && window.gtag("event", "verify_password");
  }, []);

  const handleClose = useCallback(async () => {
    delete router.query.verify;
    await router.push(router, undefined, {
      shallow: true,
    });
    props.onClose();
  }, [router, props.onClose]);

  return (
    <Dialog
      {...props}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={props.classes.modalHeader}
      >
        <span className={props.classes.modalTitle}>Complete Registration</span>
        <small style={{ marginLeft: "auto" }}>* required field</small>
        <IconButton
          className={props.classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close className={props.classes.modalClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={props.classes.modalBody}
      >
        <VerifyUserForm
          hash={router.query.verify}
          closeFromInside={handleClose}
          onSuccess={props.onSuccess}
        />
      </DialogContent>
    </Dialog>
  );
});
