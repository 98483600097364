import { createContext, useContext, useEffect, useState } from "react";
import { useUserLogoutRequest, useUserProfileGetRequest } from "../request/api";
import { useRouter } from "next/router";
import { getDashboardUrl } from "../components/Header/Header";

const AuthContext = createContext();

/**
 * Approach inspired by {@link https://blog.logrocket.com/guide-state-management-next-js/}
 */
export function AuthProvider({ children }) {
  const router = useRouter();
  const [auth, setAuth] = useState({
    user: null,
  });

  const swrConfig = {
    // onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    //   console.error("SWR Error", error, key, config, revalidate, retryCount);
    //   if (error.status === 401) {
    //     return setAuth({ user: null });
    //   }
    //   if (retryCount >= 3) return;
    //   setTimeout(() => revalidate({ retryCount }), 2000);
    // },
    shouldRetryOnError: false,
    shouldFetch: true,
  };
  const { trigger: logout, data: loggedOut } = useUserLogoutRequest(undefined, {
    withCredentials: true,
  });
  const {
    data: userProfile,
    isLoading,
    mutate: refreshUser,
  } = useUserProfileGetRequest(swrConfig, { withCredentials: true });

  useEffect(() => {
    setAuth({ user: userProfile });
  }, [userProfile]);

  let value = {
    auth,
    isLoading,
    login: async () => {
      return await refreshUser().then(({ data: user }) => user);
    },
    logout: async () => {
      await Promise.all([
        refreshUser(null, {
          revalidate: false,
          rollbackOnError: false,
        }),
        logout(),
      ]);
    },
    requireLogin: (role = []) => {
      if (isLoading) {
        return;
      }

      if (!userProfile) {
        router.push("/?login");
      }
      else if (role.length > 0 && !role.includes(userProfile.role)) {
        router.push(getDashboardUrl(userProfile));
      }
    },
    refreshUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  return useContext(AuthContext);
}
