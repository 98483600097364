import { useState } from "react";

// @material-ui/core components
import { makeStyles } from "tss-react/mui";
import { Menu, MenuItem, Divider } from "@mui/material";

// core components
import Image from "next/image.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/userOptionsStyle.js";

// local assets
import communitiesIcon from "../../public/img/forums/icons/saveIconBlue.png";
import personalInfoIcon from "../../public/img/icons/userMenu/personalInfo.png";
import dashboardIcon from "../../public/img/icons/userMenu/dashboard.png";
import careerProfileIcon from "../../public/img/icons/userMenu/careerProfile.png";
import logOutIcon from "../../public/img/icons/userMenu/logOut.png";
import {
  ROLE_ADMIN_ID, ROLE_APPRENTICE_ID,
  ROLE_EMPLOYER_ID,
  ROLE_SCHOOL_ADMIN_ID,
  ROLE_SCHOOL_USER_ID,
  ROLE_SUB_ADMIN_ID, ROLE_UNIVERSITY_ID
} from "../../util/roles";
import Link from "next/link";

const useStyles = makeStyles()(styles);

export const getDashboardPrefix = (user) => {
  if (user.role === ROLE_SCHOOL_USER_ID) {
    return "/student/";
  } else if (user.role === ROLE_EMPLOYER_ID) {
    return "/employer/";
  } else if (user.role === ROLE_UNIVERSITY_ID) {
    return "/university/";
  } else if (user.role === ROLE_APPRENTICE_ID) {
    return "/apprentice/";
  }
};

export default function UserOptionsModal(props) {
    const { classes, cx } = useStyles();
    const { open, onClose, anchorEl, handleLogout, getDashboardUrl, auth, logout } = props;

    const prefix = getDashboardPrefix(auth.user);

    const handleSelectLogout = (e) => {
        handleLogout(e);
        onClose();
    }

    return (
                <Menu className={classes.optionsMenu}
                    open={open}
                    onClose={onClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{ borderRadius: "50px !important" }}>
                    <MenuItem className={classes.optionsMenuItem} href={getDashboardUrl(auth.user)} component={Link}>
                        <Image className={classes.icon} src={dashboardIcon} alt={"dashboard-icon"} width={25} height={25} />
                        <p className={classes.optionsMenuItemText}>Dashboard</p>
                    </MenuItem>
                    <Divider />
                    {!!prefix && [
                      <MenuItem key="account" className={classes.optionsMenuItem} href={`/${prefix}/account`} component={Link}>
                        <Image className={classes.icon} src={personalInfoIcon} alt={"personal-info-icon"} width={25} height={25} />
                        <p className={classes.optionsMenuItemText}>Personal info</p>
                      </MenuItem>,
                      <Divider key="divider1" />,
                      auth.user?.role === ROLE_SCHOOL_USER_ID && [
                        <MenuItem key="profile" className={classes.optionsMenuItem} href={`/${prefix}/profile`} component={Link}>
                          <Image className={classes.icon} src={careerProfileIcon} alt={"career-profile-icon"} width={25} height={25} />
                          <p className={classes.optionsMenuItemText}>Career Profile</p>
                        </MenuItem>,
                        <Divider key="divider2" />,
                        <MenuItem key="communities" className={classes.optionsMenuItem} href={`/${prefix}/communities`} component={Link}>
                          <Image className={classes.icon} src={communitiesIcon} alt={"communities-icon"} width={25} height={25} />
                          <p className={classes.optionsMenuItemText}>Communities</p>
                        </MenuItem>,
                        <Divider key="divider3" />
                      ]
                    ]}
                    <MenuItem className={classes.optionsMenuItem} onClick={handleSelectLogout}>
                        <Image className={classes.icon} src={logOutIcon} alt={"logout-icon"} width={25} height={25} />
                        <p className={classes.optionsMenuItemText}>Logout</p>
                    </MenuItem>
                </Menu>

    )
}

