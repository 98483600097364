import React, { memo, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";
import ForgottenPasswordForm from "../ForgottenPasswordForm";
import { useRouter } from "next/router";
import ResetPasswordForm from "../ResetPasswordForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

export const ForgottenPasswordDialog = memo(function ForgottenPasswordDialog({
  ...props
}) {
  const router = useRouter();

  const handleClose = useCallback(async () => {
    delete router.query.password;
    await router.push(router, undefined, {
      shallow: true,
    });
    props.onClose();
  }, [router, props.onClose]);

  return (
    <Dialog
      {...props}
      fullWidth={true}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={props.classes.modalHeader}
      >
        <span className={props.classes.modalTitle}>
          {router.query.password === ""
            ? "Forgotten Password Or Resend Verification Email"
            : "Reset Password"}
        </span>
        <IconButton
          className={props.classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close className={props.classes.modalClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={props.classes.modalBody}
      >
        {router.query.password === "" ? (
          <ForgottenPasswordForm
            closeFromInside={handleClose}
            onSuccess={props.onSuccess}
          />
        ) : (
          <ResetPasswordForm
            hash={router.query.password}
            closeFromInside={handleClose}
            onSuccess={props.onSuccess}
          />
        )}
      </DialogContent>
    </Dialog>
  );
});
