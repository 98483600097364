import {
  container,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  tertiaryColorTint,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  warningColor,
  grayColor,
  title,
  boxShadow,
} from "../../nextjs-material-kit.js";
import { keyframes } from '@mui/system';

const lightGrey = "#D2D2D2";
const lighterGrey = "#f7f7f7";
const fadedPrimaryColor = "rgba(9, 90, 112, 0.15)";
const white = "#FFFFFF"
const bannerBlue = "#eaf6fe";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const userOptionsStyle = (theme) => ({
  desktopView: {
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  mobileView: {
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
  },
  //// Topic icons /////////////////////////////
  icon: {
    margin: "0 8px",
    color: primaryColor
  },
  optionsIcon: {
    margin: "10px",
    padding: "10px"
  },
  //// Options Menu /////////////////////////////
  optionsMenu: {
    borderRadius: "50px !important",
    "& .MuiMenu-paper": {
      padding: "0 10px",
      borderRadius: "20px !important",
    },
  },
  optionsMenuItem: {
    fontSize: "16px",
    fontWeight: "bold",
    justifyContent: "flex-start",
    padding: "10px 10px 10px 0",
    "&:hover": {
      textDecoration: "none",
      color: "black !important",
    },
    "&:focus": {
      textDecoration: "none",
      color: "black !important",
    },
    display: "flex",
    alignItems: "center",
  },
  optionsMenuItemText: {
    margin: "0 5px",
    fontSize: "0.9em",
    fontWeight: "bold",
    color: "black !important",
    "&:hover": {
      textDecoration: "none",
      color: "black !important",
    },
    "&:focus": {
      textDecoration: "none",
      color: "black !important",
    },
  },
  optionsIcon: {
    color: primaryColor,
    "&:hover": {
      color: primaryColor,
    },
  },
});

export default userOptionsStyle;

