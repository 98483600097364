import React, { memo, useCallback } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";
import StudentRegisterForm from "../RegisterForm/StudentRegisterForm";
import EmployerRegisterForm from "../EmployerRegisterForm/EmployerRegisterForm";
import TeacherRegisterForm from "../TeacherRegisterForm/TeacherRegisterForm";

import { useRouter } from "next/router";
import RegisterSwitch from "../RegisterSwitch/RegisterSwitch";
import UniversityRegisterForm from "../UniversityRegisterForm/UniversityRegisterForm";
import ApprenticeRegisterForm from "../ApprenticeRegisterForm/ApprenticeRegisterForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

export const RegisterDialog = memo(function RegisterDialog({ ...props }) {
  const router = useRouter();
  const type = router.query.register;

  const handleClose = useCallback(async () => {
    delete router.query.register;
    await router.push(router, undefined, {
      shallow: true,
    });
    props.onClose();
  }, [router, props.onClose]);

  let asText = "";

  switch (type) {
    case "student":
      asText = " as a student";
      break;
    case "employer":
      asText = "as an employer";
      break;
  }

  return (
    <Dialog
      {...props}
      fullWidth={true}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={props.classes.modalHeader}
      >
        <span className={props.classes.modalTitle}>Register {asText}</span>
        <IconButton
          className={props.classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close className={props.classes.modalClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={props.classes.modalBody}
      >
        {!type && (
          <>
            <RegisterSwitch showFooter={true} closeFromInside={handleClose} />
          </>
        )}
        {type === "student" && (
          <>
            <StudentRegisterForm
              closeFromInside={handleClose}
              email={router.query.email}
            />
          </>
        )}
        {type === "teacher" && (
          <>
            <TeacherRegisterForm
              closeFromInside={handleClose}
              email={router.query.email}
            />
          </>
        )}
        {type === "employer" && (
          <>
            <EmployerRegisterForm
              closeFromInside={handleClose}
              email={router.query.email}
            />
          </>
        )}
        {type === "apprentice" && (
          <>
            <ApprenticeRegisterForm
              closeFromInside={handleClose}
              email={router.query.email}
            />
          </>
        )}
        {type === "university" && (
          <>
            <UniversityRegisterForm
              closeFromInside={handleClose}
              email={router.query.email}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
});
