import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "tss-react/mui";

import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";

import { client, prepareCaptchaInterceptor } from "../../request/client";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  UserForRegistration,
  useStudentVerifyStep3Request,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import { FormHelperText, RadioGroup, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const useStyles = makeStyles()(styles);

export default function VerifyStudentStep3Form({
  hash,
  verifyData,
  isLoading,
  triggerNextStep,
  userVerifyError,
  closeFromInside,
}) {
  /**
   * @type {UserForRegistration}
   */
  const [registration, setRegistration] = useState({
    career_zones: [],
  });

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const [errorMessage, setErrorMessage] = useState(null);

  const { classes } = useStyles();
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const { isMutating, trigger: save } = useStudentVerifyStep3Request({
    key: hash,
  });

  useEffect(() => {
    window.gtag && window.gtag("event", "student_verify_step3_form");
  }, []);

  const handleInputChange = (event) => {
    setRegistration((registration) => ({
      ...registration,
      [event.target.name.replace("register_", "")]: event.target.value,
    }));
  };
  /**
   * Order of steps for successful registration:
   * 1. Submit pressed: `prepareCaptchaAndSubmit()`
   * 2. Captcha solved: `handleCaptchaChange()`
   * 3. Captcha new value callback sets token and calls `register()`
   */
  const prepareCaptchaAndSubmit = (event) => {
    event.preventDefault();
    recaptchaRef.current.reset(); // Make it work even if a past solution failed or timed out.
    recaptchaRef.current.execute(); // Get a token invisibly, or via a challenge if necessary.
  };

  const resetCaptcha = () => {
    // registrationComplete being true should cause the callback to auto-login
    // instead of trying to register again.
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  /**
   * @param {?string} captchaCode
   */
  const handleCaptchaChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }

    // We pass this to `register()` directly because the timing didn't work when
    // using a setter and `useState(...)` while also naively calling a submission
    // method right after.
    submit(captchaCode);
  };

  /**
   * @param {?string} captchaCode
   */
  const submit = async (captchaCode) => {
    setErrors({});
    setErrorMessage("");

    prepareCaptchaInterceptor(client, captchaCode);
    try {
      const response = await save({
        body: registration,
      });

      window.gtag && window.gtag("event", "student_verify_step3_submit");

      if (response.data.success) {
        setSuccess(true);

        triggerNextStep();
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage(
        e.response?.data?.errors?.length > 0
          ? e.response?.data?.errors[0]
          : e.response?.data?.message || e.message
      );
      setErrors(e.response.data.errors);
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={prepareCaptchaAndSubmit}>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleCaptchaChange}
          ref={recaptchaRef}
          size="invisible"
        />
        <SnackbarContent
          message="Finally, please choose three areas you're interested in so we can match you with the right opportunities."
          color="success"
          icon={Check}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="career_zones" className={classes.label}>
                Career Zones *
              </label>
              <Select
                fullWidth
                required
                id="career_zones"
                name="career_zones"
                value={registration.career_zones}
                onChange={handleInputChange}
                multiple
              >
                {(verifyData?.careerZones ?? []).map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    disabled={
                      registration.career_zones.length >= 3 &&
                      !registration.career_zones.includes(id)
                    }
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors?.career_zones}>
                {errors?.career_zones}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {errorMessage ? (
              <SnackbarContent
                message={errorMessage}
                color="warning"
                icon={Warning}
              />
            ) : (
              <>
                <div>* required field</div>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type={"submit"}
              disabled={isMutating}
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
