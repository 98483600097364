import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { useRouter } from "next/router";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import styles from "../../styles/jss/nextjs-material-kit/components/modalNag.js";
import { getCookie, setCookie } from "cookies-next";
import Image from "next/image";
import Bg1 from "/public/img/nag/bg1.png";
import Bg2 from "/public/img/nag/bg2.png";
import Bg3 from "/public/img/nag/bg3.png";
import LogoWhite from "/public/img/nag/logo_white.svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "../CustomButtons/Button.js";
import Hidden from "@mui/material/Hidden";

const useStyles = makeStyles()(styles);

export const NagDialog = memo(function NagDialog({ ...props }) {
  const router = useRouter();
  const { classes } = useStyles();
  const [values, setValues] = useState({
    email: "",
    userType: "",
  });

  const handleInputChange = useCallback((event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setCookie("hide_register_prompt", "true", {maxAge: 60 * 60 * 24 * 30});

      if (values.userType === "parent") {
        window.location.href = `https://successatschool.us3.list-manage.com/subscribe?u=2c038531b25876a2c7c9cf8b1&id=075a5d0dae&MERGE0=${encodeURI(
          values.email
        )}`;
      } else {
        router.query = {
          ...router.query,
          email: values.email,
          register: values.userType,
        };

        await router.push(router, undefined, {shallow: true});
      }
    },
    [values]
  );

  const handleClose = useCallback(async () => {
    setCookie("hide_register_prompt", "true", {maxAge: 60 * 60 * 24 * 30});

    await router.push(router);
    props.onClose();
  }, [router, props.onClose]);

  const userTypes = useMemo(
    () => ({
      student: "Student",
      employer: "Employer",
      parent: "Parent",
      teacher: "Teacher / Careers Leader",
      university: "University",
    }),
    []
  );

  if (getCookie("hide_register_prompt")) {
    return null;
  }

  return (
    <Dialog
      {...props}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      scroll="body"
    >
      <form onSubmit={handleSubmit}>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          style={{ position: "absolute", right: "20px", top: "0px" }}
          size="large"
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <Grid container>
          <Hidden smDown>
            <Grid item xs={6}>
              <Image src={Bg1} className={classes.bgImage} priority alt="" />
              <Image src={Bg2} className={classes.bgImage} priority alt="" />
              <Image src={Bg3} className={classes.bgImage} priority alt="" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} padding={4} className={classes.form}>
            <Image
              src={LogoWhite}
              alt="Success at School"
              className={classes.logo}
            />
            <h3
              style={{ backgroundImage: "url(/img/nag/header_bg.svg)" }}
              className={classes.heading}
            >
              Free Careers
              <br />
              Newsletter
            </h3>
            <h4>
              Be the first to hear about new apprenticeships, university events,
              and networking opportunities:
            </h4>
            <Grid container spacing={4} marginY={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="email" className={classes.label}>
                    Email Address:
                  </label>
                  <TextField
                    required
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleInputChange}
                    variant="filled"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <label htmlFor="email" className={classes.label}>
                    I am a:
                  </label>

                  <Select
                    required
                    id="userType"
                    name="userType"
                    value={values.userType}
                    onChange={handleInputChange}
                    fullWidth={true}
                    variant="filled"
                  >
                    {Object.entries(userTypes).map(([id, name]) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button color="turquoise" fullWidth type="submit">
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
});
