import {title} from "../nextjs-material-kit";

const modalStyle = {
  modal: {
    borderRadius: "6px",

    '.MuiIconButton-root': {
      marginRight: '-20px',
    },

    '.MuiDialogTitle-root': {
      ...title,
      margin: 0,
      backgroundColor: '#DAEBF8',
      padding: '0px 30px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiDialogTitle-root + .MuiDialogContent-root': {
      paddingTop: '24px',
    }
  },
};

export default modalStyle;
