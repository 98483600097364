import React, {useRef} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";

import styles from "../../styles/jss/nextjs-material-kit/components/customInputStyle.js";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles()(styles);

export default function CustomInput(props) {
  const { classes, cx } = useStyles();
  const inputRef = useRef(null);
  const {
    formControlProps,
    labelText,
    id,
    labelProps = {},
    inputProps = {},
    error,
    white,
    inputRootCustomClasses,
    labelVariantPosition,
    success,
    homeSearchBaseInput,
    homeSearch
  } = props;

  const labelClasses = cx({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = cx({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = cx({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = cx({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.homeSearchBaseInput]: homeSearchBaseInput,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = cx({
      [formControlProps.className]: true,
      [classes.formControl]: true,
      [classes.inputHomeSearch]: homeSearch,
    });
  } else {
    formControlClasses = classes.formControl;
  }

  labelProps['shrink'] = inputProps.value?.length > 0 ? true : undefined;

  return (
    <FormControl
      variant="standard"
      {...formControlProps}
      className={cx(formControlClasses)}>
      {labelText !== undefined ? (
        <InputLabel
          className={`${labelVariantPosition ? classes.labelRootVariant : classes.labelRoot} ${labelClasses}`}
          htmlFor={id}
          ref={inputRef}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        ref={inputRef}
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  labelVariantPosition: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  homeSearchBaseInput: PropTypes.bool,
  homeSearch: PropTypes.bool,
};
