import dashboardStyle from "./dashboardStyle";

const dashboardFormStyle = (theme) => ({
  form: {
    padding: '0 2px',
    '.MuiOutlinedInput-root': {
      // padding: '6px 8px',
      borderRadius: '30px',
      boxShadow: '0px 0px 8px 0px #4BA9A859',
      border: 'none',
      backgroundColor: 'white',

      '&.Mui-disabled': {
        backgroundColor: '#E5EBEE',
        boxShadow: 'none',
      },

      '.MuiIconButton-edgeEnd': {
        borderRadius: '30px !important',
        marginRight: '-8px !important',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #1976d2',
    },
  },
  label: {
    display: 'block',
    paddingBottom: '10px',
    color: '#26596E',
    fontWeight: '800',
  },
  tooltip: {
    background: 'rgba(75, 169, 168, 0.1)',
    padding: '8px 12px',
    borderRadius: '6px',
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px",
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "3px",
  },
});

export default dashboardFormStyle;
