import modalFormsStyle from "./modalForms.js";

const modalNagStyle = (theme) => ({
  ...modalFormsStyle(theme),
  modalBody: {
    padding: "0 !important",
  },
  modalCloseButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  modalClose: {
    fill: "#fff",
  },
  logo: {
    margin: "0 auto 20px",
    display: "block",
  },
  heading: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    textTransform: "uppercase",
    fontSize: "2rem",
    padding: "4px 0",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "30px",

    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
  },
  form: {
    background: "#005970",
    color: "#fff",

    h4: {
      color: "#fff",
      fontSize: "1.1rem",
      textAlign: "center",
      fontWeight: "bold",
      margin: "0",
    },

    label: {
      color: "#fff",
      fontSize: "1rem",
    },
    ".MuiInputBase-root, .MuiInputBase-root:hover, .MuiFormLabel-root.Mui-focused":
      {
        background: "#FFF !important",
        ".MuiInputBase-input": {
          padding: "8px 12px",
          fontSize: "1.2rem",
        },
      },
  },
  bgImage: {
    objectFit: "cover",
    width: "100%",
    height: "33.3%",
    display: "block",

    "&:nth-of-type(1)": {
      height: "33.4%",
    },
  },
});

export default modalNagStyle;
