import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "tss-react/mui";

import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";

import { client, prepareCaptchaInterceptor } from "../../request/client";
import Button from "../CustomButtons/Button";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  UserForRegistration,
  useStudentVerifyStep2Request,
  useUserResetPasswordRequest,
  useStudentVerifyGetRequest,
  useStudentEthnicitiesGetRequest,
} from "../../request/api";

import styles from "../../styles/jss/nextjs-material-kit/components/modalForms.js";
import { FormHelperText, RadioGroup, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const useStyles = makeStyles()(styles);

export default function VerifyStudentStep2Form({
  hash,
  verifyData,
  isLoading,
  triggerNextStep,
  userVerifyError,
  closeFromInside,
}) {
  /**
   * @type {UserForRegistration}
   */
  const [registration, setRegistration] = useState({
    year: "",
    gender: "",
    disability: "",
    support_worker: "",
    received_income_support: "",
    family_attended_uni: "",
    received_ema: "",
    received_fsm: "",
    distance_to_travel: "",
    // interested_in: '',
    ethnicity: "",
    ethnicity_group: "",
  });

  const {
    data: ethnicityData,
    isLoading: isLoadingEthnicity,
    error: ethnicityError,
  } = useStudentEthnicitiesGetRequest({
    parentId: registration.ethnicity_group,
  });
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const [errorMessage, setErrorMessage] = useState(null);

  const { classes } = useStyles();
  const recaptchaRef = useRef(null);
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const { isMutating, trigger: save } = useStudentVerifyStep2Request({
    key: hash,
  });

  useEffect(() => {
    window.gtag && window.gtag("event", "student_verify_step2_form");
  }, []);

  const handleInputChange = (event) => {
    setRegistration((registration) => ({
      ...registration,
      [event.target.name.replace("register_", "")]: event.target.value,
    }));
  };
  /**
   * Order of steps for successful registration:
   * 1. Submit pressed: `prepareCaptchaAndSubmit()`
   * 2. Captcha solved: `handleCaptchaChange()`
   * 3. Captcha new value callback sets token and calls `register()`
   */
  const prepareCaptchaAndSubmit = (event) => {
    event.preventDefault();
    recaptchaRef.current.reset(); // Make it work even if a past solution failed or timed out.
    recaptchaRef.current.execute(); // Get a token invisibly, or via a challenge if necessary.
  };

  const resetCaptcha = () => {
    // registrationComplete being true should cause the callback to auto-login
    // instead of trying to register again.
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  };

  /**
   * @param {?string} captchaCode
   */
  const handleCaptchaChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }

    // We pass this to `register()` directly because the timing didn't work when
    // using a setter and `useState(...)` while also naively calling a submission
    // method right after.
    submit(captchaCode);
  };

  /**
   * @param {?string} captchaCode
   */
  const submit = async (captchaCode) => {
    setErrors({});
    setErrorMessage("");

    prepareCaptchaInterceptor(client, captchaCode);
    try {
      const response = await save({
        body: registration,
      });

      window.gtag && window.gtag("event", "student_verify_step2_submit");

      if (response.data.success) {
        setSuccess(true);

        triggerNextStep();
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage(
        e.response?.data?.errors?.length > 0
          ? e.response?.data?.errors[0]
          : e.response?.data?.message || e.message
      );
      setErrors(e.response.data.errors);
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={prepareCaptchaAndSubmit}>
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleCaptchaChange}
          ref={recaptchaRef}
          size="invisible"
        />

        <SnackbarContent
          message="You’re almost there! We just need a bit more information to make sure we give you relevant, useful information."
          color="success"
          icon={Check}
        />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="gender" className={classes.label}>
                Gender *
              </label>
              <Select
                fullWidth
                required
                id="gender"
                name="gender"
                value={registration.gender}
                onChange={handleInputChange}
              >
                {Object.entries(verifyData?.genders).map(([id, name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors?.gender}>
                {errors?.gender}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="year" className={classes.label}>
                School year *
              </label>
              <Select
                fullWidth
                required
                id="year"
                name="year"
                value={registration.year}
                onChange={handleInputChange}
              >
                {Object.entries(verifyData?.years).map(([id, name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors?.year}>
                {errors?.year}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="ethnicity_group" className={classes.label}>
                Ethnicity *
              </label>
              <Select
                fullWidth
                required
                id="ethnicity_group"
                name="ethnicity_group"
                value={registration.ethnicity_group}
                onChange={handleInputChange}
              >
                {Object.entries(verifyData?.ethnicities).map(([id, name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors?.ethnicity_group}>
                {errors?.ethnicity_group}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="ethnicity" className={classes.label}>
                Sub-Ethnicity *
              </label>
              {!registration.ethnicity_group ? (
                <>
                  <p>
                    <em>Please select an ethnicity group</em>
                  </p>
                </>
              ) : isLoadingEthnicity ? (
                <>
                  <p>Loading...</p>
                </>
              ) : (
                <>
                  <Select
                    fullWidth
                    error={!!errors?.ethnicity}
                    required
                    id="ethnicity"
                    name="ethnicity"
                    value={registration.ethnicity}
                    onChange={handleInputChange}
                  >
                    {Object.entries(ethnicityData?.ethnicities).map(
                      ([id, name]) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </>
              )}
              <FormHelperText error={!!errors?.ethnicity}>
                {errors?.ethnicity}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="last_name" className={classes.label}>
                Do you have a support worker? *
              </label>
              <RadioGroup
                row
                name="support_worker"
                value={registration.support_worker}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"0"}
                  control={<Radio size="small" />}
                  label="No"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio size="small" />}
                  label="Don’t know or prefer not to say"
                />
              </RadioGroup>
              <FormHelperText error={!!errors?.support_worker}>
                {errors?.support_worker}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="last_name" className={classes.label}>
                Has anyone in your immediate family attended university? *
              </label>
              <RadioGroup
                row
                name="family_attended_uni"
                value={registration.family_attended_uni}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"0"}
                  control={<Radio size="small" />}
                  label="No"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio size="small" />}
                  label="Don’t know or prefer not to say"
                />
              </RadioGroup>
              <FormHelperText error={!!errors?.family_attended_uni}>
                {errors?.family_attended_uni}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="last_name" className={classes.label}>
                Have you ever received free school meals? *
              </label>
              <RadioGroup
                row
                name="received_fsm"
                value={registration.received_fsm}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"0"}
                  control={<Radio size="small" />}
                  label="No"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio size="small" />}
                  label="Don’t know or prefer not to say"
                />
              </RadioGroup>
              <FormHelperText error={!!errors?.received_fsm}>
                {errors?.received_fsm}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="last_name" className={classes.label}>
                Do you consider yourself to be disabled? *
              </label>
              <RadioGroup
                row
                name="disability"
                value={registration.disability}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"0"}
                  control={<Radio size="small" />}
                  label="No"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio size="small" />}
                  label="Don’t know or prefer not to say"
                />
              </RadioGroup>
              <FormHelperText error={!!errors?.disability}>
                {errors?.disability}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <label htmlFor="ethnicity" className={classes.label}>
                How far would you be willing to travel to work? *
              </label>
              <Select
                fullWidth
                error={!!errors?.distance_to_travel}
                required
                id="distance_to_travel"
                name="distance_to_travel"
                value={registration.distance_to_travel}
                onChange={handleInputChange}
              >
                {Object.entries(verifyData?.distances).map(([id, name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors?.distance_to_travel}>
                {errors?.distance_to_travel}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.tooltip}>
              Organisations sometimes want to encourage students from different
              backgrounds to apply for a job or course. Collecting this
              information allows us to keep the opportunities we inform you
              about as relevant as possible and support equality of opportunity.
            </p>
          </Grid>
          <Grid item xs={12}>
            {errorMessage ? (
              <SnackbarContent
                message={errorMessage}
                color="warning"
                icon={Warning}
              />
            ) : (
              <>
                <div>* required field</div>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              type={"submit"}
              disabled={isMutating}
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

VerifyStudentStep2Form.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
