import {cardTitle, container, primaryColor, secondaryColor, title} from "../../nextjs-material-kit.js";

/**
 * Currently used for article detail page, article list component used on `/news` and `/advice`, and some old demos.
 */
const dashboardStyle = (theme) => ({
  container: {
    ...container(theme),
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  header: {
    justifyContent: "space-between",
    marginBottom: '30px',
  },
  title: {
    ...title,
    margin: 0,
    color: primaryColor,
    fontSize: "2.5em",
  },
  bg_colored: {
    backgroundColor: "#eaf6fe",
  },
  darkBlue: {
    // backgroundColor: "#095a70 !important",
    backgroundColor: primaryColor,
  },
  blockButton: {
    display: "inline-block",
    padding: "4px 20px",
    margin: 0,
  },
  blockButtonLarge: {
    padding: "9px 20px",
    margin: 0,
  },
  block: {
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "white",
  },
  embeddedBlock: {
    borderRadius: "10px",
    overflow: "hidden",
    color: '#26596E',
    backgroundColor: "rgba(75, 169, 168, 0.2)",
  },
  blockTitle: {
    ...title,
    margin: 0,
    padding: "10px 30px",
    backgroundColor: "#DAEBF8",
    color: '#26596E',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    h2: {
      ...title,
      color: '#26596E',
      margin: 0,
      fontSize: '1.6em',
    },

    '.linkWithIcon': {
      textDecoration: 'none',
      // fontWeight: '400',
      svg: {
        marginRight: '8px',
        verticalAlign: 'middle',
      }
    },
  },
  blockNotice: {
    ...title,
    margin: 0,
    background: '#4BA9A8',
    color: 'white',
    padding: "4px 30px",

    a: {
      color: 'white',
      textDecoration: 'underline',

      '&:hover': {
        color: 'white',
        textDecoration: 'none',
      },
    },
  },
  blockMain: {
    padding: "30px",
    // fontSize: '0.9em',
    color: 'black',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',

    "h1, h2, h3, h4, h5, h6": {
      fontFamily: "var(--font-brandon-grotesque)",
      fontWeight: 900,
      margin: 0,
      color: '#26596E',
    },

    "h4": {
      fontSize: '1.3em',
    },

    "h5": {
      fontSize: '1.1em',
    },

    // strong: {
    //   fontWeight: '500',
    // }
  },
  hr: {
    borderTop: "1px solid #26596E",
    margin: "0 30px",
    padding: "30px 0",
  },
  blockTitleAlt: {
  },
  blockFooter: {
    backgroundColor: "#f7fbfe",
  },
  logo: {
    boxShadow: "0px 0px 8px 0px #4BA9A859",
    backgroundColor: "white",
    borderRadius: "10px",
    textAlign: "center",
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    img: {
      margin: '0 auto',
      maxWidth: '90%',
    },
  },
  logoWrapper: {
    padding: '10px',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',

    em: {
      width: '100%',
    },
  },
  blockList: {
    marginTop: '20px',
  },
  blockListColumns: {
    display: 'flex',
  },
  blockListItem: {
    borderBottom: '1px solid rgba(38, 89, 110, 0.2)',
    paddingBottom: '8px',
    paddingTop: '12px',
    flexWrap: 'nowrap',
    display: 'flex',
  },
  blockCol: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '> div': {
      flex: '1',
    },
  },
  blockListEmpty: {
    justifyContent: "center",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  blockListItemNoBorder: {
    borderBottom: 'none',
  },
  blockListIcon: {
    width: '56px',
    textAlign: 'center',
    alignSelf: 'center',
    marginRight: '12px',
  },
  blockListMain: {
    flexGrow: 1,
  },
  icon: {
    boxShadow: '0px 0px 8px 0px rgba(75, 169, 168, 0.35)',
    borderRadius: '100%',
    padding: '10px',
    display: 'inline-block',
    lineHeight: '0',

    svg: {
      width: '30px',
      height: '30px',
    },
    img: {
      objectFit: 'contain',
    },
  },
  iconNoPadding: {
    padding: '0',
  },
  upload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '6px 20px',
    fontSize: '0.9em',
    color: '#FFFFFF',
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    backgroundColor: '#4BA9A8',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  avatar: {
    backgroundColor: "#ECF6FD",
    borderRadius: "10px",
    width: '100%',
    padding: '10%',
    display: 'flex',
    alignItems: 'center',

    img: {
      borderRadius: "100%",
      width: '100%',
    },
  },
  profileBox: {
    flexGrow: 1,
    lineHeight: '1.8em',
  },
  callout: {
    paddingTop: '16px',
    paddingBottom: '16px',

    button: {
      marginTop: '9px',
    },
  },

  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0',

    thead: {
      backgroundColor: "rgba(75, 169, 168, 0.2)",
    },
    th: {
      ...title,
      color: '#26596E',
      fontSize: '1.3em',
      padding: '12px 20px',

      '&:first-child': {
        borderRadius: '5px 0 0 5px',
      },
      '&:last-child': {
        borderRadius: '0 5px 5px 0',
      }
    },
    tbody: {
      tr: {
        borderBottom: '1px solid #4BA9A866',

        '&:last-child': {
          borderBottom: 'none',
        }
      },
    },
    td: {
      padding: '12px 20px',
    },
    '.action-icon path': {
      fill: '#4BA9A8',
    }
  },
  followedCommunityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "5px 0",
  },
  followedCommunityInner: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  followedCommunityLink: {
    margin: "auto 0 auto 20px"
  },
  unfollowButton: {
    borderRadius: "50px !important",
    padding: "10px 20px",
  }
});

export default dashboardStyle;
