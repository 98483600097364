import {hexToRGBAlpha, primaryColor, secondaryColor, tertiaryColor, tertiaryColorTint} from "../../nextjs-material-kit";
import dashboardFormStyle from "../pages/dashboardFormStyle";

const modalFormsStyle = (theme) => ({
    ...dashboardFormStyle(theme),
    ctaButton: {
        fontSize: "18px",
        marginTop: "0",
        fontFamily: "var(--font-brandon-grotesque)",
        fontWeight: 700,
        textTransform: "none",
        backgroundColor: tertiaryColor,
        marginBottom: "0px",
        lineHeight: "16px",
        display: "flex",
        boxShadow: "none",
        padding: "6.5px 30px",
        "&:hover": {
            backgroundColor: tertiaryColorTint,
        },
        [theme.breakpoints.down('lg')]: {
            padding: "12px 30px",
        },
        [theme.breakpoints.down('md')]: {
            padding: "13px 5px",
        },
    },
    divider: {
        marginTop: "30px",
        marginBottom: "0px",
        textAlign: "center",
    },
    inputIconsColor: {
        color: "#495057",
    },

    // Checkboxes.
    checked: {
        color: primaryColor + "!important",
    },
    checkRoot: {
        padding: "12px",
        "&:hover": {
            backgroundColor: hexToRGBAlpha(primaryColor, 0.04) + "!important",
        },
    },
    blockButton: {
        width: "100%",
    },
    label: {
        display: 'block',
        paddingBottom: '10px',
        color: '#26596E',
        fontWeight: '800',
    }
});

export default modalFormsStyle;
