import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Check from "@mui/icons-material/Check";
import SnackbarContent from "../Snackbar/SnackbarContent";

import {
  useStudentVerifyGetRequest,
  useUserVerifyGetRequest,
} from "../../request/api";

import VerifyStudentStep1Form from "../VerifyStudentStep1Form";
import VerifyStudentStep2Form from "../VerifyStudentStep2Form";
import { useRouter } from "next/router";
import { ROLE_SCHOOL_ADMIN_ID, ROLE_SCHOOL_USER_ID } from "../../util/roles";
import VerifyTeacherForm from "../VerifyTeacherForm";
import VerifyStudentStep3Form from "../VerifyStudentStep3Form/index.js";

export default function VerifyUserForm({ hash, closeFromInside }) {
  const router = useRouter();
  const {
    data: verifyData,
    mutate: triggerNextStep,
    isLoading,
    error: userVerifyError,
  } = useUserVerifyGetRequest({ key: hash });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (userVerifyError?.response?.status === 404) {
    return (
      <SnackbarContent
        message={<>Your account has been verified successfully .</>}
        color="success"
        icon={Check}
      />
    );
  }

  if (verifyData.roleId === ROLE_SCHOOL_USER_ID) {
    if (verifyData.step === 1) {
      return (
        <VerifyStudentStep1Form
          verifyData={verifyData}
          isLoading={isLoading}
          userVerifyError={userVerifyError}
          closeFromInside={closeFromInside}
          triggerNextStep={triggerNextStep}
          hash={hash}
        />
      );
    } else if (verifyData.step === 2) {
      return (
        <VerifyStudentStep2Form
          verifyData={verifyData}
          isLoading={isLoading}
          userVerifyError={userVerifyError}
          closeFromInside={closeFromInside}
          triggerNextStep={triggerNextStep}
          hash={hash}
        />
      );
    } else {
      return (
        <VerifyStudentStep3Form
          verifyData={verifyData}
          isLoading={isLoading}
          userVerifyError={userVerifyError}
          closeFromInside={closeFromInside}
          triggerNextStep={triggerNextStep}
          hash={hash}
        />
      );
    }
  }

  if (verifyData.roleId === ROLE_SCHOOL_ADMIN_ID) {
    return (
      <VerifyTeacherForm
        verifyData={verifyData}
        isLoading={isLoading}
        userVerifyError={userVerifyError}
        closeFromInside={closeFromInside}
        triggerNextStep={triggerNextStep}
        hash={hash}
      />
    );
  }
}

VerifyUserForm.propTypes = {
  closeFromInside: PropTypes.func.isRequired,
};
